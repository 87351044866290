.pricing{
    width: 100%;
    padding: 6rem 1rem;
    background-color:  rgba(225, 218, 218, 0.9);
}
.card-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px ;
    
}
.card{
    border: 1px solid #997979;
    color:#8b5c51;
    text-align: center;
    padding: 1rem;
}
.card:hover{
    background-color:#cfb6b1;
}
.card h3{
    font-size: 1.8rem;
    padding: 1rem;    
    text-decoration: underline;
    font-weight: bold;
}
.card p{
    padding: 10px 0;
    color: #8b5c51;
}
.bar{
    border-bottom: 1px solid #883943;
    width: 10%;
    margin: 1.5rem auto;
    display: block;
}
.btc{
    font-size: 3rem;
    font-weight: 600;
    padding: 1rem 0;
}
.card .btn{
    display: block;
    width: 80%;
    margin: 2rem auto;
}

@media screen and (max-width:740px) {
    .card-container{
        max-width: 1140px;
        margin: auto;        
        grid-template-columns: 1fr;
    }
}