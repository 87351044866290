.header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
    mix-blend-mode: multiply;   
}
.header-bg{
    background-color: #F1D6CF;
    transition: 0.5;
}

.logo-header{
    max-width: 340px;
    margin-top: 5rem;
    height: 200px;
    width: 200px;
    
}

.nav-menu{
    display: flex;  
    
}

.nav-menu li{
    padding: 0 1rem;    
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: underline;
}

.hamburger{
    display: none;
}

@media screen and (max-width: 1040px) {
    ::-webkit-scrollbar {
        width: 0px;
    }
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background:black;
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
        opacity: 1;
    }
    
    .nav-menu.active    {
        left: 0;
    }
    .nav-menu li{
        padding: 1rem 0;
    }
    .nav-menu li a{
        font-size: 2rem;
        
    }
    .hamburger{
        display: initial;       
       
    }
    .logo-header{
        max-width: 340px;
        margin-top: 2rem;
        height: 150px;
        width: 150px;
        
    }
}