.hero-img{
    width: 100%;
    height: 60vh;
    background-color: rgba(239, 220, 215, 0.7);
    position: relative;
    ;
}
.heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.hero-img::before{
    content: '';
    background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wqUMHAWugUtC3g1G2rhnM2FxupweNaXtTh3-4pd8G-2xLPd_Ex-Xi_eV2fniq-n5QWI&usqp=CAU');
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
   

}
.hero-img h1{
    font-size: 2.4rem;
}
.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width:640px) {
    .hero-img h1{
        font-size: 2rem;
    }
   
}