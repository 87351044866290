.mask{
    width: 100%;
    height: 80vh;
    position: relative;
}
.into-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}
.mask::after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(237, 227, 224);
    opacity: 0.6;
}
.hero{
    height: 100%;
    width: 100%;
}
.hero .content{
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    margin-top: -50px;
}
.hero .content h1{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
    color: black;
}
.hero .content p{
    font-size: 2rem;
    line-height: 35px;
    text-transform:unset;
    font-family: Arial, Helvetica, sans-serif;
    color: #831601;
    font-weight: bold;
    
}
.content .btn{
    margin: 1rem 0.2rem;
    
}

@media screen and (max-width:640px) {
    .hero .content{
        max-width: 300px;
        margin-top: 1px;
    }
    .content .btn{
        margin: 0 auto;
        font-size: 0.5rem;
        
    }
    .hero .content h1{
        font-size: 1.7rem;       
    }
    .hero .content p{
        font-size: 1.2rem;
    }
    
}