.product-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;
}
.product-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px ;
    padding-bottom: 5rem;
}
.product-card{
    background:  rgba(136,57,67,0.1);
    padding: 1.2rem 1rem;
    height: 650px;
}
.product-card img{
    width: 100%;
    min-height: 450px;
}
.product-title{
    color:#dcc8cb;
    padding: 1rem 0;
    text-align: center;
    text-decoration: underline;
}
.pro-details p{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
    color: aliceblue;
}
.pro-btns{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}
.pro-btns .btn{
    padding: 0.5rem 1rem;
}


@media screen and (max-width:740px) {
    .product-container{
        max-width: 90%;  
        margin: auto;
        grid-template-columns: 1fr;    
    }
    
}
